html{
  height: 100%;
}
body {
  background-color: #BCD883;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#444444;
}
#root{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.container{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.login-form{
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input-field{
  margin-bottom:10px;
}
.card{
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 15px;
}
.button{
  background-color:#444444;
  font-weight: normal;
  color:white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.title{
  color:#444444;
}
.new-task label{
    font-weight:700;
  }
.input:active,.input:focus{
  border: 1px solid #96c13d;
  box-shadow: 0 0 0 1px #96c13d;
  outline: 0;
}

.icon{
  font-size: 25px;
  color: rgb(122, 174, 15);
}
.search-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 2px;
  color: #444444;
  background: white;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border: 1px solid #f8f9fa;
  padding:0 1rem;
}
.input-holder{
  margin-top:10px;
}
.float-button{
  position: fixed;
  left: 20px;
  bottom: 30px;
  font-size: 60px;
  background: #fff;
  border-radius: 100%;
  height: 60px;
  line-height: 0;
}
.dropdown-item.selected{
  background-color:#bcd883;
}