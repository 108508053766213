.navbar-img{
    max-height:50px;
}

.navbar {
    background-color:#96c13d  !important;
    padding-top:0 !important;
    padding-bottom:0 !important;
}
.offcanvas {
    background-color: #96c13d  !important;
}
.offcanvas .navbar-nav .nav-link{
    color:#444444;
    cursor: pointer;
    font-weight: 500;
}
.offcanvas-title{
    width:100%;
}
.btn-close{
 float:right;
}
.navbar-toggler{
    border-color:#444444 !important;
}