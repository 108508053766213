.table-item{
    color:#444444;
    margin-bottom:10px;
    padding:5px;
    border-radius: 5px;
    border: 2px solid #3b3b3b;
    position: relative;
}
.bg-white{
    background-color:white;
    border-radius: 10px;
}
.bg-transparent{
    background-color:transparent;
}
.background-image{
    background-repeat:no-repeat;
    width:100%;
    height:100%;
    position: absolute;
    opacity:0.5;
    background-position: right;
}
.table-item p{
    margin-bottom:0;
}
.table-item span{
    margin-bottom:0;
    font-size:14px;
}
.back-btn{
    background-color: #444 !important;
    color: white !important;
    font-weight: bold !important;
}
.page-head{
    text-align: center;
    justify-content: space-between;
    align-items:center;
}
.clickable{
    position:relative;
    cursor: pointer;
}